import moment from "moment/moment";


export const formatValue = (value, type, format) => {

    if (type === 'percentage') {
        return Intl.NumberFormat().format(value) + '%'
    } else if (type === 'date') {
        const useFormat = format === undefined ? "DD/MM/YY" : format;
        return moment(value).format(useFormat);
    } else if (type === 'number') {
        return Intl.NumberFormat().format(value);
    } else {
        return value;
    }

}