import React from "react";
import {ExportIcon} from "../../../../../../components/Export/ExportIcon/ExportIcon";
import {Col, Row, message, Flex, Card, Button, Tooltip} from "antd";
import {Title} from "../../../../../../components/DesignSystem/Typography/Title/Title";
import {InlineEdit} from "../../../../../../components/DesignSystem/InlineEdit/InlineEdit";
import {exportService} from "../../../../../../services/exportService";
import {RunExportButton} from "../../../../../../components/ExportDetail/table/RunExportButton";
import {getErrorFromResponse} from "../../../../../../utils/error.utils";
import PropTypes from "prop-types";
import {ExportDocumentPublicUrlButton} from "../../../../../../components/Export/ExportDocumentPublicUrlButton";
import {FieldTimeOutlined} from "@ant-design/icons";
import {StatisticsHeader} from "../StatisticsHeader";
import {ShowDashboardButton} from "../../../../../../components/ExportDetail/table/ShowDashboardButton";
import {DraftIndicator} from "../../../../../../components/Export/DraftIndicator";

export const EditExportDetailHeader = ({
                                           exportDefinition,
                                           subscriptionLimit,
                                           companySubscriptionState,
                                           onChangedName,
                                           onStartExport,
                                           onSeeDetails,
                                           statistics,
                                           schedule
                                       }) => {

    const handleNameChange = (value) => {
        exportService.updateExportName(exportDefinition.id, value).then(() => {
            message.info("Export name has been updated");
            onChangedName();
        }).catch(error => {
            message.error("Export name hasn't been updated. " + getErrorFromResponse(error));
        })
    }

    return (<Card>
        <Row align="middle">
            <Col sm={16}>
                <Flex align="center">
                    <ExportIcon source={exportDefinition.source} target={exportDefinition.target}/>
                    <Flex align={"middle"}>
                        <Title>
                            <InlineEdit onChange={handleNameChange} value={exportDefinition.name}/>
                        </Title>
                        <DraftIndicator definitionState={exportDefinition.definitionState}/>
                    </Flex>
                </Flex>
            </Col>
            <Col sm={8}>
                <div style={{textAlign: "right", paddingRight: "15px"}}>
                    {exportDefinition.template != null && (<ShowDashboardButton record={exportDefinition}/>)}
                    <RunExportButton record={exportDefinition} subscriptionLimit={subscriptionLimit}
                                     companySubscriptionState={companySubscriptionState}
                                     onClick={() => onStartExport(exportDefinition.id)}/>{' '}
                    {exportDefinition.id && (<Tooltip title={"Show history or schedule an export"}>
                        <Button type="text" onClick={() => onSeeDetails(exportDefinition.id)} size={"large"}
                         icon={<FieldTimeOutlined style={{fontSize: '20px'}}/>}/>
                    </Tooltip>)}
                    {exportDefinition.documentPublicUrl !== undefined && (<ExportDocumentPublicUrlButton
                        type={exportDefinition.target}
                        documentPublicUrl={exportDefinition.documentPublicUrl}/>)}
                </div>
            </Col>
        </Row>
        <StatisticsHeader object={exportDefinition.object} statistics={statistics} schedule={schedule}/>
    </Card>)

}

EditExportDetailHeader.propTypes = {
    exportDefinition: PropTypes.object.isRequired,
    subscriptionLimit: PropTypes.number.isRequired,
    companySubscriptionState: PropTypes.string.isRequired,
    onChangedName: PropTypes.func.isRequired,
    onStartExport: PropTypes.func.isRequired,
    onSeeDetails: PropTypes.func.isRequired
}