import React from "react";
import {Spin, Result, Row, Col} from 'antd';
import {Link, useHistory} from "react-router-dom";
import {Title} from "../../../DesignSystem/Typography/Title/Title";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import {AlertIfMessage} from "../../../DesignSystem/AlertIfMessage/AlertIfMessage";
import {PublicContent} from "../PublicContent";
import {RegistrationInputsForm} from "./RegistrationInputsForm";
import {SubTitle} from "../../../DesignSystem/Typography/SubTitle/SubTitle";
import PropTypes from "prop-types";
import {MicrosoftButton} from "../../../DesignSystem/MicrosoftButton/MicrosoftButton";
import {SpacerWithText} from "../../../DesignSystem/SpacerWithText/SpacerWithText";

const RegistrationForm = ({state, error, onFinish}) => {

    let history = useHistory();

    const handleMicrosoftLogin = () => {
        history.push('/oauth/microsoft/login');
    }

    if (state === 'registered') {
        return <PublicContent><Result
            title="A registration email has been sent to your email. Please read your email and click on a validation link."/></PublicContent>
    } else {
        return <PublicContent>
            <Spin spinning={state === 'in-progress'}>
                <Title>Sign up</Title>
                <SubTitle>Do you already have an account? <Link to="/login">Please, Sign In.</Link></SubTitle>
                <Spacer size={"large"}/>

                <MicrosoftButton text={'Sign up with Microsoft'} onClick={handleMicrosoftLogin}/>

                <SpacerWithText size={"large"} border={true} text={"Or"}/>

                <AlertIfMessage message={error}/>
                <RegistrationInputsForm onSave={onFinish}/>
            </Spin>
        </PublicContent>
    }
};

export default RegistrationForm;

RegistrationForm.propTypes = {
    state: PropTypes.oneOf(['registered', 'in-progress', 'error']),
    error: PropTypes.string,
    onFinish: PropTypes.func
}
