import {Col, Divider, Empty, Row, Select, Spin} from "antd";
import React, {useState} from "react";
import style from "../../Step/ConnectionSelect.module.css";
import {AddConnection} from "../AddConnection";
import {TextCenter} from "../../DesignSystem/Center/TextCenter";
import {Spacer} from "../../DesignSystem/Spacer/Spacer";
import {ReloadOutlined} from "@ant-design/icons";
import {SilverTitle} from "../../DesignSystem/Typography/SilverTitle/SilverTitle";
import {SelectButton} from "../../DesignSystem/DynamicForm/components/SelectButton";
import PropTypes from "prop-types";
const {Option} = Select;

export const ConnectionItem = ({connection}) => {
    return <Row align="middle">
        <Col>
        </Col>
        <Col>
            {" "}<span className={style.name}>{connection.name}</span>
        </Col>
    </Row>
}

ConnectionItem.propTypes = {
    connection: PropTypes.object.isRequired
}

const NoConnectionFoundContent = ({connectionType}) => {

    return <Empty style={{padding: "10px"}}
                  imageStyle={{
                      height: 80,
                  }}
                  description="There are no connections defined yet">
        <AddConnection type={"primary"} connectionType={connectionType}/>
        <Spacer/>
        <TextCenter><SilverTitle>
            If you need more info please visit our documentation about <a href={"https://www.apimoon.com/help/manage-connections"} target="_blank" rel="noreferrer">How to create a
            connection.</a></SilverTitle></TextCenter>
    </Empty>


}

NoConnectionFoundContent.propTypes = {
    connectionType: PropTypes.string.isRequired
}

const getDynamicSelectDropdownRender = (menu, onRefresh, reloading, connectionTyep) => {

    return <Spin spinning={reloading}>
        {menu}
        <><Divider
            style={{
                margin: '8px 0',
            }}
        />
            <span>
                <div><SelectButton icon={<ReloadOutlined/>} onClick={onRefresh} title={"Refresh"}/></div>
                <div>
                    <AddConnection type={"text"} connectionType={connectionTyep}/>
                </div>
                </span>
        </>
    </Spin>

}

export const ConnectionSelect = ({name, value, onChange, properties, onReload, disabled = false}) => {

    const [reloading, setReloading] = useState(false);

    if (value === undefined && properties.options !== undefined && properties.options.length > 0) {
        console.log("selection first value");
        onChange(properties.options[0].id);
    }

    const handleRefresh = () => {
        setReloading(true);
        onReload(name).then(() => {
        }).finally(() => setReloading(false));
    }

    if (properties.options) {
        return <Select value={value} onChange={onChange} disabled={disabled} notFoundContent={<NoConnectionFoundContent connectionType={properties?.fetch?.type}/>} dropdownRender={(menu) =>
            getDynamicSelectDropdownRender(menu, handleRefresh, reloading, properties?.fetch?.type)}>
            {
                properties.options.map(connection => <Option key={connection.id} value={connection.id}><ConnectionItem
                    connection={connection}/></Option>)
            }
        </Select>
    } else {
        return <Select></Select>
    }

}

ConnectionSelect.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    properties: PropTypes.object.isRequired,
    onReload: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}