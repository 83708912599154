import {Spacer} from "../../DesignSystem/Spacer/Spacer";
import React from "react";
import {PageHeader} from "@ant-design/pro-layout";
import {BigColumn} from "../../DesignSystem/BigColumn/BigColumn";
import {ConfigProvider, Table} from "antd";
import {BorderedIcon} from "../../DesignSystem/Icons/BorderedIcon";

export const FlowDraftOrTemplate = ({connection, onBack, onSelect}) => {

    return <div><Spacer/><PageHeader
        onBack={onBack}
        className="site-page-header-responsive"
        title={`Import from ${connection.type} ...`}>
        <Spacer/>
        <FlowDraftOrTemplateTable onSelect={onSelect}/>
    </PageHeader></div>

}

const FlowDraftOrTemplateTable = ({onSelect}) => {

    const options = [
        {
            key: 'scratch',
            name: 'Start from scratch'
        },
        {
            key: 'template',
            name: 'Use a Dashboard template'
        }
    ];

    const columns = () => [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <div
                name={record.name}
                style={{cursor: 'pointer', fontSize: '18px', fontWeight: '400', lineHeight: '1.5', padding: '10px'}}
                onClick={e => onSelect(record)}
                type={record.type}>{record.name}</div>
        }
    ];

    return <ConfigProvider><Table
        rowKey="id"
        dataSource={options}
        columns={columns()}
        showHeader={false}
        pagination={false}
        size={"small"}
    /></ConfigProvider>

}