import {FlowExistingConnections} from "./FlowExistingConnections";
import React, {useState} from "react";
import {FlowAddConnection} from "./FlowAddConnection";
import {Spacer} from "../../DesignSystem/Spacer/Spacer";
import {PageHeader} from "@ant-design/pro-layout";
import {FlowDraftOrTemplate} from "./FlowDraftOrTemplate";
import {FlowTemplate} from "./FlowTemplate";

export const FlowConnectionsStep = ({connections, onSelect}) => {

    const [mode, setMode] = useState("list");
    const [connection, setConnection] = useState(undefined);

    const handleBackAddConnection = () => {
        setMode("list");
    }

    const handleConnectionSelect = (connection) => {
        setConnection(connection);
        setMode("type");
    }

    const handleBack = () => {
        setMode("list");
        setConnection(undefined);
    }

    const handleSelect = (record) => {
        if (record.key === 'scratch') {
            onSelect(connection);
        } else {
            setMode("templates");
        }
    }

    const handleFlowTemplateBack = () => {
        setMode("type");
    }

    const handleSelectTemplate = (template) => {
        onSelect(connection, template);
    }

    if (mode === "type") {
        return <FlowDraftOrTemplate connection={connection} onSelect={handleSelect} onBack={handleBack}/>
    } else if (mode === "templates") {
        return <FlowTemplate onBack={handleFlowTemplateBack} onSelectTemplate={handleSelectTemplate}/>
    } else {
        return <div><Spacer/><PageHeader
            className="site-page-header-responsive"
            title={'Import data from already connected systems ...'}>
            <Spacer/>
            <FlowExistingConnections connections={connections} onSelect={handleConnectionSelect}/>
            <Spacer size={"large"}/>
            <div style={{color: "gray", padding: '10px'}}>or connect to your system</div>
            <FlowAddConnection connections={connections} onSelect={onSelect} onBack={handleBackAddConnection}/>
        </PageHeader></div>
    }

}