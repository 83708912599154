import {useEffect} from "react";
import {oauthService} from "../../../services/oauthService";

export const LoginMicrosoftPage = () => {

    useEffect(() => {
        oauthService.getLoginMicrosoftCode().then(response => {
            window.location = response.data.url;
        })
    });

    return <div></div>

}