import {Col, Row, Select} from "antd";
import React from "react";
import style from "./ConnectionTypeSelect.module.css";
import PropTypes from "prop-types";
const {Option} = Select;

export const ConnectionTypeItem = ({item}) => {
    return <Row align="middle">
        <Col>
            <img src={`/connectors/${item.type}.svg`} width={20} height={20} alt={`Connector type ${item.type}`}  align={"left"} className={style.image}/>
        </Col>
        <Col>
            {" "}<span className={style.name}>{item.name}</span>
        </Col>
    </Row>
}

ConnectionTypeItem.propTypes = {
    item: PropTypes.object.isRequired
}


export const ConnectionTypeSelect = ({onSelect, items}) => {

    const handleSelect = (value) => {
        onSelect(value, items);
    }

    return <Select onSelect={handleSelect}>
        {items.map(item => <Option key={item.type} value={item.type}><ConnectionTypeItem item={item}/></Option>)}
    </Select>

}

ConnectionTypeSelect.propTypes = {
    onSelect: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired
}