import React, {Skeleton} from "antd";
import {useEffect, useState} from "react";
import {publicDashboardTemplateService} from "../../../../services/publicDashboardTemplateService";
import {CenteredDesign} from "../../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {Spacer} from "../../../../components/DesignSystem/Spacer/Spacer";
import {DashboardTemplateCard} from "./DashboardTemplateCard";
import {TextCenter} from "../../../../components/DesignSystem/Center/TextCenter";
import {useHistory} from "react-router-dom";

export const DashboardTemplatesPage = () => {

    const history = useHistory();

    const [templates, setTemplates] = useState(undefined);

    useEffect(() => {

        publicDashboardTemplateService.fetchTemplates().then(response => {
            setTemplates(response.data);
        });

    }, []);

    const handleTemplateSelect = (templateId) => {
        history.push(`/dashboard-templates/${templateId}/preview`);
    }

    if (templates === undefined) {
        return <Skeleton/>
    } else {
        return <CenteredDesign sm={18} lg={14}>
            <Spacer size={"large"}/>
            <TextCenter><h2 className="webflow-h2">Dashboard Templates</h2></TextCenter>
            <Spacer size={"large"}/>
            <Spacer size={"large"}/>
            <TextCenter><h2>Choose from a variety of dashboard templates to get started with your dashboard.</h2></TextCenter>
            <Spacer size={"large"}/>
            <Spacer size={"large"}/>

            {templates.map(template => {
                return <span>
                    <DashboardTemplateCard template={template} key={template.id} onSelect={handleTemplateSelect}/>
                    <Spacer size={"large"}/>
                </span>
            })}

        </CenteredDesign>
    }

}