import React from "react";
import {useHistory} from "react-router-dom";
import {ExportIcon} from "../../../../../../components/Export/ExportIcon/ExportIcon";
import {Col, Row, message, Button, Tooltip, Flex, Card} from "antd";
import {Title} from "../../../../../../components/DesignSystem/Typography/Title/Title";
import {exportService} from "../../../../../../services/exportService";
import {RunExportButton} from "../../../../../../components/ExportDetail/table/RunExportButton";
import {InlineEdit} from "../../../../../../components/DesignSystem/InlineEdit/InlineEdit";
import {getErrorFromResponse} from "../../../../../../utils/error.utils";
import PropTypes from "prop-types";
import {ExportDocumentPublicUrlButton} from "../../../../../../components/Export/ExportDocumentPublicUrlButton";
import SettingOutlined from "@ant-design/icons/lib/icons/SettingOutlined";
import {StatisticsHeader} from "../StatisticsHeader";
import {ShowDashboardButton} from "../../../../../../components/ExportDetail/table/ShowDashboardButton";
import {DraftIndicator} from "../../../../../../components/Export/DraftIndicator";
import {ReloadOutlined} from "@ant-design/icons";

export const ExportSnapshotsDetailHeader = ({
                                                companySubscriptionState,
                                                children, exportDefinition,
                                                subscriptionLimit,
                                                statistics, onChangedName, onStartExport,
                                                onRefresh,
                                                editable = false,
                                                schedule,
                                                showStatistics = true,
                                                showDashboard,
                                                buttons
                                            }) => {

    let history = useHistory();

    const handleActionMenuClick = (event) => {
        if (event === 'edit') {
            history.push(`/exports/${exportDefinition.id}/edit`);
        }
    }

    const handleNameChange = (value) => {
        exportService.updateExportName(exportDefinition.id, value).then(() => {
            message.info("Export name has been updated");
            onChangedName();
        }).catch(error => {
            message.error("Export name hasn't been updated. " + getErrorFromResponse(error));
        })
    }

    return (
        <Card>
            <Row align="middle">
                <Col sm={12}>
                    <Flex align="center">
                        <ExportIcon source={exportDefinition.source} target={exportDefinition.target}/>
                        <span>
                        <Flex align={"middle"}>
                            <Title>
                                <InlineEdit onChange={handleNameChange} value={exportDefinition.name}/>
                            </Title>
                            <DraftIndicator definitionState={exportDefinition.definitionState}/>
                        </Flex>
                    </span>
                    </Flex>
                </Col>
                <Col sm={12}>
                    <div style={{textAlign: "right"}}>
                        {exportDefinition.template != null && (
                            <ShowDashboardButton record={exportDefinition} onClick={() => showDashboard(exportDefinition.id)}/>)}
                        <RunExportButton record={exportDefinition} subscriptionLimit={subscriptionLimit}
                                         companySubscriptionState={companySubscriptionState} onClick={onStartExport}/>{" "}
                        <Tooltip title={"Edit export definition"}>
                            <Button onClick={() => handleActionMenuClick('edit')} type={"text"} size={"large"}
                                    icon={<SettingOutlined style={{fontSize: '20px'}}/>}
                                    disabled={companySubscriptionState !== 'OK' || !editable}/>{" "}
                        </Tooltip>
                        {exportDefinition.documentPublicUrl !== undefined && (<ExportDocumentPublicUrlButton
                            type={exportDefinition.target}
                            documentPublicUrl={exportDefinition.documentPublicUrl}/>)}
                        {buttons?.map((button, index) => {
                            return <span key={index}>{button}</span>
                        })}
                    </div>
                </Col>
            </Row>
            {showStatistics && (
                <StatisticsHeader object={exportDefinition.object} statistics={statistics}
                                  schedule={schedule}
                                  rightPanel={<Tooltip title={"Refresh table"}>
                                      <Button icon={<ReloadOutlined/>} type="text" onClick={() => onRefresh()}/>
                                  </Tooltip>}/>
            )}
            {children}
        </Card>
    )

}

ExportSnapshotsDetailHeader.propTypes = {
    editable: PropTypes.bool,
    companySubscriptionState: PropTypes.string,
    children: PropTypes.node,
    exportDefinition: PropTypes.object,
    subscriptionLimit: PropTypes.number,
    statistics: PropTypes.object,
    onChangedName: PropTypes.func,
    onStartExport: PropTypes.func,
    setVisible: PropTypes.func,
    onRefresh: PropTypes.func
}