import React from "react";
import styles from "./SpacerWithText.module.css";
import PropTypes from "prop-types";

export function SpacerWithText({text, size}) {
    let className = size === undefined ? styles.small : styles[size];
    return <div className={`${className}, ${styles.border}`}><span className={styles.text}>{text}</span></div>;
}

SpacerWithText.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    border: PropTypes.bool
}