import {Button, Image} from "antd";
import style from "./MicrosoftButton.module.css";

export const MicrosoftButton = ({text, onClick}) => {

    return <Button
        type="primary"
        className={style.button}
        onClick={onClick}
        icon={<Image src={"ms-login.svg"} preview={false}/>}
        style={{width: '100%', height: '40px', fontWeight: '600', color: '#FFFFFF', background:'#2F2F2F', fontFamily: 'Segoe UI Regular', fontSize: '15px'}}
    >
        {text}
    </Button>

}