import React, {useEffect, useRef, useState} from "react";
import {CenteredDesign} from "../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {Card, Spin, Skeleton, message, Result, Button, Tooltip} from "antd";
import {Spacer} from "../../../components/DesignSystem/Spacer/Spacer";
import {useHistory, useParams} from "react-router-dom";
import {exportService} from "../../../services/exportService";
import {ExportSnapshotsDetailHeader} from "../project/backups/exports/snapshots/ExportSnapshotsDetailHeader";
import PropTypes from "prop-types";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {getErrorFromResponse} from "../../../utils/error.utils";
import {DownloadOutlined} from "@ant-design/icons";
import {DashboardPanel} from "./DashboardPanel";

export const DashboardsPage = ({user}) => {

    let {exportId} = useParams();
    let history = useHistory();

    const [loadingContext, setLoadingContext] = useState({loading: false, tip: undefined});
    const [exportDefinition, setExportDefinition] = useState(undefined);
    const [subscriptionLimit, setSubscriptionLimit] = useState(undefined);
    const [statistics, setStatistics] = useState(undefined);
    const [schedule, setSchedule] = useState(undefined);
    const [exportSnapshotId, setExportSnapshotId] = useState(undefined);
    const [showExportStatus, setShowExportStatus] = useState(false);
    const [exportSnapshotStatus, setExportSnapshotStatus] = useState(undefined);
    const [editable, setEditable] = useState(false);
    const [dashboard, setDashboard] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {
        fetchExport();
    }, [exportId]); // eslint-disable-line react-hooks/exhaustive-deps

    function fetchExport() {
        setLoadingContext({loading: true});
        exportService.fetchExport(exportId).then(response => {
            setExportDefinition(response.data.export);
            setSubscriptionLimit(response.data.subscriptionLimit);
            setStatistics(response.data.statistics);
            setSchedule(response.data.schedule);
            setEditable(response.data.editableMode === 'YES');
        }).catch(e => {
            message.warning("Required export no longer exists.");
            history.push("/exports");
        }).finally(() => {
            setLoadingContext({loading: false});
        })

        setErrorMessage(undefined);

        exportService.fetchDashboardExport(exportId).then(response => {
            setDashboard(response.data);
        }).catch(error => {
            setErrorMessage(getErrorFromResponse(error));
        }).finally(() => {
            setLoadingContext({loading: false});
        })


    }

    const exportRef = useRef();

    const handleExportToPDF = async () => {
        const element = exportRef.current;
        const canvas = await html2canvas(element, {useCORS: true}); // Ensures cross-origin images are included
        const dataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF();

        const imgWidth = 190; // Adjust width as needed
        const pageHeight = 297; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

        let position = 0; // Top position for new pages
        pdf.addImage(dataURL, 'PNG', 10, position, imgWidth, imgHeight);

        if (imgHeight > pageHeight) {
            let heightLeft = imgHeight - pageHeight;
            while (heightLeft > 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(dataURL, 'PNG', 10, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
        }

        pdf.save('export.pdf');
    };

    function startExport() {
        setErrorMessage(undefined);
        exportService.startExport(exportId).then(response => {
            setShowExportStatus(false);
            setExportSnapshotId(response.data);
        }).catch(error => {
            setErrorMessage(getErrorFromResponse(error));
        })
    }

    if (errorMessage !== undefined) {

        return <CenteredDesign><Result
            status="warning"
            title="A required dashboard can't be loaded."
            subTitle={errorMessage}
        /></CenteredDesign>

    }

    if (exportDefinition === undefined || dashboard === undefined) {
        return <Skeleton/>
    }

    return <CenteredDesign><Spin spinning={loadingContext.loading} tip={loadingContext.tip}>

        <ExportSnapshotsDetailHeader
            editable={editable}
            exportDefinition={exportDefinition}
            companySubscriptionState={user.companyPlan.companySubscriptionState}
            subscriptionLimit={subscriptionLimit}
            statistics={statistics}
            showStatistics={false}
            onChangedName={fetchExport}
            schedule={schedule}
            onStartExport={startExport}
            buttons={[<Tooltip title={"Export dashboard to pdf"}><Button type={"text"} icon={<DownloadOutlined style={{fontSize: '20px'}}/>}
                                                                         size="large" onClick={handleExportToPDF}/></Tooltip>]}
        />
        <Spacer/>
        <Card ref={exportRef}>
            <DashboardPanel dashboard={dashboard} lastSuccessfulExecution={exportDefinition.lastSuccessfulExecution}/>
        </Card>
    </Spin>
    </CenteredDesign>
}

DashboardsPage.propTypes = {
    user: PropTypes.object.isRequired
};