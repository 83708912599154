import axios from "axios";
import {axiosService} from "../axios";

export const TOKEN_SESSION_ATTRIBUTE_NAME = "token";

export const authenticationService = {

    executeBasicAuthenticationService(username, password) {
        localStorage.removeItem(TOKEN_SESSION_ATTRIBUTE_NAME);
        return axios.post(`/authenticate`, {username, password});
    },

    registerSuccessfulLoginForJwt(token) {
        localStorage.setItem(TOKEN_SESSION_ATTRIBUTE_NAME, token);
    },

    logout() {
        return new Promise((resolve, reject) => {
            axiosService.get(`/api/users/logout`).then(response => {
                localStorage.removeItem(TOKEN_SESSION_ATTRIBUTE_NAME);
                resolve(response);
            })
        });
    },

    isUserLoggedIn() {
        return localStorage.getItem(TOKEN_SESSION_ATTRIBUTE_NAME) != null;
    },

    getToken() {
        return localStorage.getItem(TOKEN_SESSION_ATTRIBUTE_NAME);
    },

    removeToken() {
        localStorage.removeItem(TOKEN_SESSION_ATTRIBUTE_NAME);
    }
};
