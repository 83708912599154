import React from "react";
import {Footer} from "antd/lib/layout/layout";
import {Col, Row} from "antd";
import {useHistory} from "react-router-dom";

export const PageFooter = () => {

    let history = useHistory();

    const showTemplates = () => {
        history.push('/dashboard-templates');
    }

    return <Footer style={{ backgroundColor: 'white'}}>
        <Row align={"middle"}>
            <Col sm={12}>
                ©  2024 apimoon&nbsp;&nbsp;|
                &nbsp;&nbsp;<a href="https://www.apimoon.com/terms-and-conditions" className={"footer-link"}>Terms and conditions</a>
                &nbsp;&nbsp;|
                &nbsp;&nbsp;<a href="https://www.apimoon.com/privacy-policy" className={"footer-link"}>Privacy policy</a>
                &nbsp;&nbsp;|&nbsp;&nbsp;<a onClick={showTemplates} className={"footer-link"}>Dashboard templates</a>
            </Col>
            <Col sm={12} style={{textAlign: 'right'}}>
                Apimoon is an automated data platform
            </Col>
        </Row>

    </Footer>

}