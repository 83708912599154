import React, {useState} from "react";
import {Form, Input, Button, Row, Col, Alert} from 'antd';
import {authenticationService} from "../../../../services/authentication.service";
import {Title} from "../../../DesignSystem/Typography/Title/Title";
import {AlertIfMessage} from "../../../DesignSystem/AlertIfMessage/AlertIfMessage";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import {Link, useHistory} from "react-router-dom";
import {userService} from "../../../../services/user.service";
import {Spinner} from "../../../DesignSystem/Spinner/Spinner";
import {getErrorFromResponse} from "../../../../utils/error.utils";
import {SubTitle} from "../../../DesignSystem/Typography/SubTitle/SubTitle";
import {PublicContent} from "../PublicContent";
import {useCookies} from "react-cookie";
import {PIPEDRIVE_COOKIE} from "../../../../views/apps/ouath/PipedriveCallback";
import {MicrosoftButton} from "../../../DesignSystem/MicrosoftButton/MicrosoftButton";

const LoginForm = () => {

    let history = useHistory();

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const [cookies] = useCookies([PIPEDRIVE_COOKIE]);

    const [form] = Form.useForm();

    const handleMicrosoftLogin = () => {
        history.push('/oauth/microsoft/login');
    }

    function handleSubmit(values) {

        setLoading(true);

        authenticationService
            .executeBasicAuthenticationService(
                values.username,
                values.password
            ).then(response => {

            authenticationService.registerSuccessfulLoginForJwt(response.data.token);

            userService.me().then(response => {
                window.location = "/exports";
            }).finally(() => {
                setLoading(false);
            });

        }).catch(error => {

            if (error.response.status === 401) {
                setError(getErrorFromResponse(error));
            } else {
                setError('Login service is not available');
            }

        }).finally(() => {
            setLoading(false);
        })
    }

    return <>
        <PublicContent width={"800px"}>
            {cookies[PIPEDRIVE_COOKIE] && <Alert type={"warning"}
                                                 showIcon={true}
                                                 message={"Apimoon marketplace application info"}
                                                 description="To complete the linking of your Pipedrive account with Apimoon, simply log
            in or sign up."/>}
            <Spinner spinning={loading}>
                <Title>Sign in</Title>
                <SubTitle>Don't have an account? <Link to="/sign-up">Please, Sign up.</Link></SubTitle>
                <Spacer size={"large"}/>
                <Row gutter={40}>
                    <Col sm={12} style={{borderRight: '1px solid silver'}}>
                        <Spacer size={"large"}/>
                        <Spacer size={"large"}/>
                        <MicrosoftButton text={'Sign in with Microsoft'} onClick={handleMicrosoftLogin}/>
                    </Col>
                    <Col sm={12}><AlertIfMessage message={error}/>
                        <Form
                            layout='vertical'
                            form={form}
                            onFinish={handleSubmit}
                        >
                            <Form.Item name="username" label="Username" rules={[
                                {
                                    required: true
                                },
                                {
                                    type: 'email',
                                    message: 'Please insert a valid email address',
                                },
                            ]}>
                                <Input placeholder="Username"/>
                            </Form.Item>
                            <Form.Item name="password" label="Password" rules={[{required: true}]}>
                                <Input placeholder="Password" type={"password"}/>
                            </Form.Item>
                            <Form.Item>
                                <div style={{textAlign: 'right'}}>
                                    <Link to="/reset-password-request">Forgot password?</Link>
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Sign In</Button>
                            </Form.Item>
                        </Form></Col>
                </Row>
            </Spinner>
        </PublicContent></>
};

export default LoginForm;
