import queryString from 'query-string';
import {oauthService} from "../../../services/oauthService";
import {useEffect, useState} from "react";
import {authenticationService} from "../../../services/authentication.service";
import {userService} from "../../../services/user.service";

export const LoginMicrosoftCallback = ({location}) => {

    const [error, setError] = useState(undefined);

    useEffect(() => {

        let params = queryString.parse(location.search);

        if (params.error) {
            setError(params.error);
        } else {
            oauthService.getLoginMicrosoftAccessToken(params.code).then(response => {
                authenticationService.registerSuccessfulLoginForJwt(response.data.token);
                userService.me().then(() => {
                    window.location = "/exports";
                });
            });
        }

    }, [location]);

    if (error) {
        return <div>{error}</div>
    }

}