import React from 'react';
import {Flex, Select} from "antd";
import {ConnectionTypeItem} from "../../../components/Connections/connection-type-select/ConnectionTypeSelect";

const {Option} = Select;

export const TemplatesFilter = ({sources, targets}) => {

    return <Flex align={"center"}>

        <Select style={{width: '250px'}} allowClear={true} placeholder={"All Sources"}>
            {sources.map(item => <Option key={item.type} value={item.type}><ConnectionTypeItem item={item}/></Option>)}
        </Select>
        &nbsp;&nbsp;
        <Select style={{width: '250px'}} allowClear={true} placeholder={"All Targets"}>
            {targets.map(item => <Option key={item.type} value={item.type}><ConnectionTypeItem item={item}/></Option>)}
        </Select>

    </Flex>

}