import React, {useEffect, useState} from "react";
import {Spacer} from "../../../components/DesignSystem/Spacer/Spacer";
import {CenteredDesign} from "../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {PageHeader} from "@ant-design/pro-layout";
import {Card, Col, Row, Skeleton} from "antd";
import {connectionTypeService} from "../../../services/connectionTypeService";
import {TemplatesFilter} from "./TemplatesFilter";
import {TemplateCard} from "./TemplateCard";
import {templateService} from "../../../services/templateService";

export const TemplatesPage = () => {

    const [sources, setSources] = useState(undefined);
    const [targets, setTargets] = useState(undefined);
    const [templates, setTemplates] = useState(undefined);

    useEffect(() => {

        connectionTypeService.fetchConnectionTypes().then(response => {
            const sources = response.data.filter(type => type.direction === "IN");
            const targets = response.data.filter(type => type.direction === "OUT");
            setSources(sources);
            setTargets(targets);
        });

        templateService.fetchTemplates().then(response => {
            setTemplates(response.data);
        });

    }, []);

    if (sources === undefined || targets === undefined || templates === undefined) {
        return <Skeleton/>
    } else {
        return <CenteredDesign>
            <Spacer size={"large"}/>
            <PageHeader className="site-page-header-responsive" title={"Dashboard templates"}>
                <Card>
                    <TemplatesFilter sources={sources} targets={targets}/>
                </Card>
                <Spacer/>
                <Row gutter={18}>
                    {templates.map(template => {
                        return <Col sm={8} key={template.name}><TemplateCard template={{name: template.name, id: template.id}}/></Col>
                    })}
                </Row>
            </PageHeader>
        </CenteredDesign>
    }

}