import {Spacer} from "../../DesignSystem/Spacer/Spacer";
import React, {useEffect, useState} from "react";
import {PageHeader} from "@ant-design/pro-layout";
import {BigColumn} from "../../DesignSystem/BigColumn/BigColumn";
import {Card, Col, ConfigProvider, Row, Skeleton, Table} from "antd";
import {BorderedIcon} from "../../DesignSystem/Icons/BorderedIcon";
import {connectionTypeService} from "../../../services/connectionTypeService";
import {templateService} from "../../../services/templateService";
import {TemplateCard} from "../../../views/apps/templates/TemplateCard";

export const FlowTemplate = ({onBack, onSelectTemplate}) => {

    const [templates, setTemplates] = useState(undefined);

    useEffect(() => {
        templateService.fetchTemplates().then(response => {
            setTemplates(response.data);
        });
    }, []);

    if (templates === undefined) {
        return <Skeleton/>
    }

    return <div><Spacer/><PageHeader
        onBack={onBack}
        className="site-page-header-responsive"
        title={'Please select which template do you want to use'}>
        <Spacer/>
        <Row gutter={[16, 16]}>
            {templates.map(template => {
                return <Col sm={8} key={template.name}><TemplateCard template={template} onSelect={onSelectTemplate}/></Col>
            })}
        </Row>
    </PageHeader></div>

}