import React, {Button, Card, Col, message, Row} from "antd";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import {useEffect, useState} from "react";
import {PipedriveMappingTableSelectObject} from "./PipedriveMappingTableSelectObject";
import {ExportMappingTableColumnsSelection} from "../ExportMappingTableColumnsSelection";
import {columns} from "../ExportMappingTable";
import {PageHeader} from "@ant-design/pro-layout";
import {BackButton} from "../../../DesignSystem/BackButton/BackButton";
import {PipedriveFilters} from "./PipedriveFilters";
import {ExportMappingTableComponent} from "../ExportMappingTableComponent";
import {templateService} from "../../../../services/templateService";
import {publicDashboardTemplateService} from "../../../../services/publicDashboardTemplateService";

export const PipedriveMappingTable = ({
                                          data,
                                          processStepsService,
                                          mapping,
                                          onSelected,
                                          onRemoved,
                                          onColumnMoved,
                                          onChangeMapping,
                                          onSave,
                                          onBack,
                                          onHeaderMenuClick,
                                          exportMappingType,
                                      }) => {

    const [supportedObjects, setSupportedObjects] = useState(undefined);
    const [selectedObject, setSelectedObject] = useState(undefined);
    const [fields, setFields] = useState(undefined);
    const [preview, setPreview] = useState([]);
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(undefined);
    const [validPayload, setValidPayload] = useState(false);
    const [template, setTemplate] = useState(undefined);

    useEffect(() => {

        processStepsService.getProcessStepData("pipedrive", "supported-objects", {connection: data.connection}).then(response => {

            const supportedObjects = response.data;

            setSupportedObjects(supportedObjects);

            if (data.filter !== undefined) {
                setSelectedFilter(data.filter);
            }

            setSelectedObject(supportedObjects.find(object => object.value === data.object));

            setValidPayload(data.object !== undefined);

        });
    }, [data]);

    useEffect(() => {
        loadPreview(selectedFilter);
    }, [selectedObject]);

    const handleObjectChange = (value) => {

        const selectedObject = supportedObjects.find(object => object.value === value);
        setSelectedObject(selectedObject);
        setValidPayload(selectedObject !== undefined);

    }

    const handleOnChangeObject = () => {

        if (data?.template !== undefined) {
            message.warning("You can't change the object when using a template");
            return;
        }

        setSelectedObject(undefined);
        setSelectedFilter(undefined);
        setFields(undefined);
        setPreview(undefined);
        setValidPayload(false);
        onChangeMapping([]);
    }

    const loadPreview = (filter) => {
        if (selectedObject !== undefined) {
            setLoadingPreview(true);

            const payload = {
                object: selectedObject.value, connection: data.connection, template: data.template
            }

            if (filter !== undefined) {
                payload.filter = filter;
            }

            if (data.template !== undefined) {
                publicDashboardTemplateService.fetchTemplateById(data.template).then(response => {
                    setTemplate(response.data);
                }).catch(error => {
                    setTemplate(undefined);
                });
            }

            processStepsService.getProcessStepData("pipedrive", "fields", payload).then(response => {
                setFields(response.data.fields);
                setPreview(response.data.preview);
                if (mapping.length === 0) {
                    onChangeMapping(response.data.defaultMapping);
                }
            }).finally(() => {
                setLoadingPreview(false);
            });
        }
    }

    const handleOnChangeFilter = (value) => {
        setSelectedFilter(value);
        loadPreview(value);
    }

    const handleOnSave = () => {

        const payload = {
            mapping: mapping, values: {
                object: selectedObject.value,
                connection: data.connection
            },
            template: data.template
        }

        if (selectedFilter !== undefined) {
            payload.values.filter = selectedFilter;
        }

        onSave(payload);
    }

    const title = template !== undefined ? <span>Data Preview <span style={{fontSize: '12px', color: 'silver'}}>based on {template.name} template</span></span> : "Data" +
        " Preview";

    return <PageHeader className="site-page-header-responsive" title={title}>
        <Card style={{minHeight: '80vh'}}>
            <Row>
                <Col sm={24}>
                    <Row align="middle">
                        <Col sm={6}>
                            <h4><BackButton onBack={onBack}/> Pipedrive</h4>
                        </Col>
                        <Col sm={18}>
                            <Row>
                                <Col sm={12}>
                                    {selectedObject !== undefined && selectedObject.filter.filterable &&
                                        <PipedriveFilters connectionId={data.connection}
                                                          value={selectedFilter}
                                                          object={selectedObject.value} onChange={handleOnChangeFilter}/>}
                                </Col>
                                <Col sm={12} style={{textAlign: 'right'}}>
                                    <Button type={"primary"} onClick={handleOnSave} disabled={!validPayload}>Save and Continue</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Spacer/>
                    <Row gutter={14}>
                        <Col sm={6} style={{backgroundColor: '#FAFAFA', padding: '30px', borderRadius: '8px', border: '1px solid #f0f0f0'}}>
                            {selectedObject !== undefined && <span>
                    <HeaderChange title={selectedObject.label} onChange={handleOnChangeObject}/>
                    <Spacer border={true}/>
                    <ExportMappingTableColumnsSelection fields={fields}
                                                        mapping={mapping}
                                                        onRemoved={onRemoved}
                                                        onSelected={onSelected}/>
                </span>}
                            {selectedObject === undefined && <PipedriveMappingTableSelectObject objects={supportedObjects}
                                                                                                onChange={handleObjectChange}/>}

                        </Col>
                        <Col sm={18} style={{backgroundColor: 'white'}}>
                            {selectedObject !== undefined && (
                                <ExportMappingTableComponent data={preview}
                                                             loading={loadingPreview}
                                                             loadingMessage={"Connecting to Pipedrive ..."}
                                                             columns={columns(mapping, onHeaderMenuClick, exportMappingType)}
                                                             onMoved={onColumnMoved}/>)}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    </PageHeader>

}

export const HeaderChange = ({onChange, title}) => {

    return <Row gutter={14}>
        <Col sm={12}>
            <strong>{title}</strong>
        </Col>
        <Col sm={12} style={{textAlign: 'right'}}>
            <a onClick={onChange}>change</a>
        </Col>
    </Row>

}

