import React, {useState, useEffect, useMemo} from "react";
import {Layout, Menu} from 'antd';
import {useHistory} from "react-router-dom";
import CloudDownloadOutlined from "@ant-design/icons/lib/icons/CloudDownloadOutlined";
import {
    ApiOutlined,
    BarChartOutlined,
    DashboardOutlined,
    DollarOutlined,
    FundViewOutlined,
    GiftOutlined,
    MessageOutlined,
    TeamOutlined
} from "@ant-design/icons";
import SettingOutlined from "@ant-design/icons/lib/icons/SettingOutlined";

const {Sider} = Layout;

const DEFAULT_SIDEBAR_COLLAPSED = "defaultSiderCollapsed";
const SIDEBAR_CLOSED = "closed";
const SIDEBAR_OPEN = "open";

export const DefaultSidebar = () => {

    let history = useHistory();

    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([history.location.pathname]);
    const [defaultSiderCollapsed, setDefaultSiderCollapsed] = useState(SIDEBAR_CLOSED);

    const keysRules = [
        {
            pattern: "/settings",
            openKey: "settings"
        }
    ];

    const onTitleClick = (value) => {
        if (value.key === "settings") {
            history.push(`/settings`);
        }
    }

    const items = [
        {
            label: 'Exports',
            key: `/exports`,
            icon: <CloudDownloadOutlined/>
        },
        {
            label: 'Connections',
            key: `/connections`,
            icon: <ApiOutlined />
        },
        {
            label: 'Users',
            key: '/users',
            icon: <TeamOutlined />
        },
        {
            label: 'Account usage',
            key: '/usage',
            icon: <BarChartOutlined />
        },
        {
            label: 'Billing',
            key: '/billing',
            icon: <DollarOutlined />
        },
        {
            label: 'Settings',
            key: 'settings',
            icon: <SettingOutlined/>,
            onTitleClick: onTitleClick,
            children: [
                {label: 'Profile', key: `/settings`},
                {label: 'Company', key: `/settings/company`}
            ],
        },
        {
            label: 'Help Center',
            key: '/help-center',
            icon: <MessageOutlined />
        }
    ];


    const openKeys = useMemo(() => {

        const collapsed = window.localStorage.getItem(DEFAULT_SIDEBAR_COLLAPSED) || SIDEBAR_OPEN;
        setDefaultSiderCollapsed(collapsed);

        if (collapsed === SIDEBAR_CLOSED) {
            return undefined;
        } else {
            return keysRules
                .filter(keyRule => history.location.pathname.includes(keyRule.pattern))
                .map(keysRule => keysRule.openKey);
        }

    }, [history.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {

        if (history.location.pathname.includes("/exports/")) {
            setDefaultSelectedKeys([`/exports`]);
        }

    }, [history.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps


    const showPage = (values) => {
        if (values.key === '/help-center') {
            const win = window.open("https://apimoon.com/help", "_blank");
            win.focus();
        } else {
            history.push(values.key);
        }
    }

    const onCollapse = (value) => {
        const collapsed = value ? SIDEBAR_CLOSED : SIDEBAR_OPEN
        setDefaultSiderCollapsed(collapsed);
        window.localStorage.setItem(DEFAULT_SIDEBAR_COLLAPSED, collapsed);
    }

    return <Sider width={250}
                  className="sider"
                  theme={"light"}
                  collapsible collapsed={defaultSiderCollapsed === SIDEBAR_CLOSED}
                  onCollapse={onCollapse}
    >
        <Menu
            onClick={showPage}
            openKeys={openKeys}
            style={{height: '100%', borderRight: '1px solid #f0f0f0'}}
            defaultOpenKeys={openKeys}
            selectedKeys={defaultSelectedKeys}
            mode="inline"
            items={items}/>
    </Sider>

}