import React from 'react';
import {Spacer} from "../../../components/DesignSystem/Spacer/Spacer";
import {Button} from "antd";
import {EyeOutlined} from "@ant-design/icons";

export const TemplateCard = ({template, onSelect}) => {

    const handleSelect = () => {
        onSelect(template);
    }

    const handleShowPreview = () => {
        window.open(`/dashboard-templates/${template.id}/preview`, '_blank');
    }

    return <div style={{backgroundColor: 'white', border: '1px solid #F0F0F0', borderRadius: '5px', minHeight: '300px', padding: '10px'}}>
        <img src={`/templates/${template.id}.png`} width={"100%"} alt={`Connector type ${template.type}`}
        />
        <div style={{padding: '20px', textAlign: 'center'}}>
            <Spacer size={"small"} border={true}/>
            <div style={{fontSize: '16px', fontWeight: 'bolder'}}>{template.name}</div>
            <div style={{paddingTop: '10px'}}>{template.description}</div>
            <div style={{paddingTop: '10px', textAlign: 'center'}}>
                <Button onClick={handleSelect} type={"primary"}>Use template</Button>{' '}
                <Button onClick={handleShowPreview} icon={<EyeOutlined />}>Preview</Button>
            </div>
        </div>

    </div>

}