import React, {Tooltip} from "antd";
import {DashboardLabel} from "./DashboardLabel";
import {FormattedDate} from "../../../components/DesignSystem/Moment/FormattedDate";
import {InfoCircleOutlined} from "@ant-design/icons";


export const MetadataCol = ({title, data, type, message}) => {

    if (data == null && message != null) {
        return <span>
            <DashboardLabel title={`${title}: `}/>
                <Tooltip title={message}>Data Not available <InfoCircleOutlined style={{cursor: 'pointer'}}/></Tooltip>
        </span>
    }

    if (type === 'range') {
        return <div>
            <DashboardLabel title={`${title}: `}/>
            <span>{<FormattedDate value={data[0].from}/>} - <FormattedDate value={data[0].to}/></span>
        </div>
    } else {
        return <div>
            <DashboardLabel title={title}/>
            <span>{JSON.stringify(data)}</span>
        </div>
    }

}