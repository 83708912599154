import React, {Card, Skeleton} from "antd";
import {useEffect, useState} from "react";
import {publicDashboardTemplateService} from "../../../../services/publicDashboardTemplateService";
import {CenteredDesign} from "../../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {Spacer} from "../../../../components/DesignSystem/Spacer/Spacer";
import {useParams} from "react-router-dom";
import {DashboardPanel} from "../../dashboards/DashboardPanel";

export const DashboardTemplatePreviewPage = () => {

    let {dashboardTemplateId} = useParams();

    const [dashboard, setDashboard] = useState(undefined);
    const [dashboardTemplate, setDashboardTemplate] = useState(undefined);

    useEffect(() => {
        publicDashboardTemplateService.fetchDashboardTemplatePreview(dashboardTemplateId).then(response => {
            setDashboard(response.data.dashboard);
            setDashboardTemplate(response.data.dashboardTemplate);
        }).catch(e => {
            console.error(e);
        });
    }, [dashboardTemplateId]);

    if (dashboard === undefined || dashboardTemplate === undefined) {
        return <CenteredDesign>
            <Skeleton/>
        </CenteredDesign>
    }

    return <CenteredDesign sm={18} lg={18}>

        <h1>Live preview of {dashboardTemplate.name}</h1>

        <h2>{dashboardTemplate.description}</h2>

        <Spacer size={"small"} border={true}/>
        <Spacer/>

        <Card>
            <DashboardPanel dashboard={dashboard} lastSuccessfulExecution={new Date()}/>
        </Card>
    </CenteredDesign>

}