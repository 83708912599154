import React from 'react';
import {Flex, Statistic, Tooltip} from "antd";
import {formatNumber} from "../../../utils/number.utils";
import {CustomCard} from "../../../components/DesignSystem/CustomCard/CustomCard";
import {Bar, Pie, Column} from "@ant-design/charts";
import {TextCenter} from "../../../components/DesignSystem/Center/TextCenter";
import {Spacer} from "../../../components/DesignSystem/Spacer/Spacer";
import {InfoCircleOutlined} from "@ant-design/icons";
import {formatValue} from "./dashboard.utils";

export const DashboardCol = ({type, title, data, message, configuration}) => {

    if (data == null && message != null) {
        return <CustomCard>
            <TextCenter>{title}</TextCenter>
            <Flex vertical align={"center"} justify={"center"} style={{height: '250px'}} gap={20}>
                <TextCenter><h2>Data not available</h2></TextCenter>
                <Tooltip title={message}>More info <InfoCircleOutlined style={{cursor: 'pointer'}}/></Tooltip>
            </Flex>
        </CustomCard>
    }

    if (type === 'number' || type === 'decimal' || type === 'currency') {

        var value = formatNumber(data);

        return (
            <Statistic title={title} value={value}/>
        )

    } else if (type === 'bar') {

        const config = {
            data,
            xField: configuration.xField.field,
            yField: configuration.yField.field,
            color: configuration?.color,
            yAxis: {
                label: {
                    style: {
                        fill: '#000', // Optional: Set label color
                    },
                    formatter: (value) => formatValue(value, configuration.yField.type, configuration.yField.format)
                },
            },
            xAxis: {
                label: {
                    formatter: (value) => formatValue(value, configuration.xField.type)
                },
            },
            tooltip: {
                formatter: (item) => {
                    return {
                        title: formatValue(item[configuration.yField.field], configuration.yField.type, configuration.yField.format),
                        name: configuration.xField.field,
                        value: formatValue(item[configuration.xField.field], configuration.xField.type, configuration.xField.format),
                    };
                },
            },
            label: {
                style: {
                    fill: '#000', // Optional: Set label color
                },
                formatter: (item) => formatValue(item[configuration.xField.field], configuration.xField.type, configuration.xField.format)
            },
        };

        return <CustomCard>
            <TextCenter>{title}</TextCenter>
            <Spacer/>
            <Bar {...config} style={{height: '250px'}}/>
        </CustomCard>

    } else if (type === 'pie') {

        var config = {
            appendPadding: 10,
            data,
            angleField: 'value',
            colorField: 'name',
            label: {
                type: 'inner',
                offset: '-30%',
                content: function content(_ref) {
                    return ''.concat(_ref.value, '%');
                },
                style: {
                    fontSize: 14,
                    textAlign: 'center',
                },
            },
            interactions: [{type: 'element-active'}],
        };

        return <CustomCard>
            <TextCenter>{title}</TextCenter>
            <Spacer/>
            <Pie {...config} style={{height: '250px'}}/>
        </CustomCard>

    } else if (type === "column") {

        const config = {
            data,
            xField: configuration.xField.field,
            yField: configuration.yField.field,
            color: configuration?.color,
            xAxis: {
                label: {
                    formatter: (value) => formatValue(value, configuration.xField.type)
                },
            },
            yAxis: {
                label: {
                    formatter: (value) => formatValue(value, configuration.yField.type, configuration.yField.format)
                },
            },
            tooltip: {
                formatter: (item) => {
                    return {
                        title: formatValue(item[configuration.xField.field], configuration.xField.type, configuration.xField.format),
                        name: configuration.yField.field,
                        value: formatValue(item[configuration.yField.field], configuration.yField.type, configuration.yField.format),
                    };
                },
            },
        }

        return <CustomCard>
            <TextCenter>{title}</TextCenter>
            <Spacer/>
            <Column {...config} style={{height: '250px'}}/>
        </CustomCard>

    } else {
        return <h1>{title}</h1>
    }

}