import React from "react";
import {columns} from "./ExportsTable.columns";
import {PageableTable} from "../../DesignSystem/PageableTable/PageableTable";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";

export const ExportsTable = ({fetchData, renderEmpty, user, reload, handleSelect}) => {

    return <PageableTable
        rowKey="id"
        columns={columns(user, handleSelect)}
        fetch={fetchData}
        reload={reload}
        showHeader={false}
        topPadding={22}
        renderEmpty={renderEmpty}
    />

}

ExportsTable.propTypes = {
    fetchData: PropTypes.func.isRequired,
    renderEmpty: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    reload: PropTypes.any,
    handleSelect: PropTypes.func.isRequired
}