import React, {useMemo} from "react";
import {Button, Tooltip} from "antd";
import PropTypes from "prop-types";
import {FundViewOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";

export const ShowDashboardButton = ({record}) => {

    const history = useHistory();

    const showDashboard = () => {
        history.push(`/exports/${record.id}/dashboard`);
    }

    const disabled = useMemo(() => {
        return record.definitionState === 'DRAFT' || record.lastSuccessfulExecution == null;
    }, [record]); // eslint-disable-line

    const tooltip = useMemo(() => {

        if (record.definitionState === "DRAFT") {
            return "Dashboard can't be shown because the export configuration is not fully defined";
        } else if (record.lastSuccessfulExecution == null) {
            return "Dashboard can't be shown because the export was not yet successfully finished";
        } else {
            return "";
        }

    }, [record]);

    return <Tooltip title={tooltip}><span>
        <Button type="text" icon={<FundViewOutlined style={{fontSize: '22px'}} />} size={"large"} disabled={disabled} onClick={showDashboard}>Dashboard</Button></span>
    </Tooltip>


}

ShowDashboardButton.propTypes = {
    record: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
}