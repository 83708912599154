import { axiosServiceWithoutToken} from "../axios";

export const publicDashboardTemplateService = {

    fetchTemplates: () => {
        return axiosServiceWithoutToken.get(`/api/dashboard-templates`)
    },

    fetchTemplateById: (dashboardTemplatedId) => {
        return axiosServiceWithoutToken.get(`/api/dashboard-templates/${dashboardTemplatedId}`)
    },


    fetchConnectionTypes: () => {
        return axiosServiceWithoutToken.get(`/api/dashboard-templates/connection-types`);
    },

    fetchDashboardTemplatePreview: (dashboardTemplateId) => {
        return axiosServiceWithoutToken.get(`/api/dashboard-templates/${dashboardTemplateId}/preview`);
    }

}