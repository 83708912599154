import axios from "axios";
import {authenticationService} from "../services/authentication.service";

export function createAxiosClient(useToken = true) {
    const axiosClient = axios.create({
        headers: {
            "Content-Type": "application/json;charset=UTF-8"
        }
    });

    axiosClient.interceptors.response.use((response) => {
        return response
    }, async function (error) {

        if (error.response.status !== 401) {
            return Promise.reject(error);
        }

        return axios.get('/refreshtoken', {
                headers: {
                    'Cache-Control': 'no-store, no-cache',
                    'Authorization': "Bearer " + authenticationService.getToken(),
                    "isRefreshToken": "true"
                }
            }
        ).then(response => {
            authenticationService.registerSuccessfulLoginForJwt(response.data.token);
            error.response.config.headers['Authorization'] = 'Bearer ' + response.data.token;
            return axios(error.response.config);
        }).catch(error => {
            authenticationService.removeToken();
            window.location = "/login";
            return Promise.reject(error);
        })

    });


    axiosClient.interceptors.request.use(config => {
        if (authenticationService.isUserLoggedIn() && useToken) {
            config.headers.Authorization = "Bearer " + authenticationService.getToken();
        }
        return config;
    });

    return axiosClient;
}
