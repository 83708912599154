import React from 'react';
import {Flex} from "antd";

import style from "./DashboardTemplateCard.module.css";
import {BorderedIcon} from "../../../../components/DesignSystem/Icons/BorderedIcon";
import {getSourceName} from "../../../../utils/download.utils";
import {CustomCard} from "../../../../components/DesignSystem/CustomCard/CustomCard";

export const DashboardTemplateCard = ({template, onSelect}) => {

    return <CustomCard className={style.test} onClick={() => onSelect(template.id)}>
        <Flex gap={40}>
            <img src={`/templates/${template.id}.png`} width={"500px"} height={"250px"} alt={`Connector type ${template.type}`}/>
            <Flex vertical justify={"center"}>
                <h2 className="webflow-h5">{template.name}</h2>
                <div style={{fontSize: '24px', marginBottom: '20px', marginTop: '10px'}}>{template.description}</div>
                <Flex align={"center"}>
                    <BorderedIcon image={`/process-types/${template.source}.svg`} tooltip={getSourceName(template.source)}/>
                    <span className={style.arrow}>&gt;</span>
                    <BorderedIcon image={`/process-types/ms-excel.svg`} tooltip={"MS Excel"}/>
                    <BorderedIcon image={`/process-types/google-sheets.svg`} tooltip={"Google Sheets"}/>
                </Flex>
            </Flex>
        </Flex>
    </CustomCard>

}